<template>
    <div class="pa-2">
        <div>
            <NavBar/>
        </div> 
        <v-app>
            <v-card class="pa-4" v-for="(seg, index) of segmentList" :key="index">
                <v-card-title>
                    Add Segments
                </v-card-title>
                <v-card-text>
                <!-- Enabling -->
                <div style="position: absolute; right: 0; top:0;">
                        <v-switch v-model="isEnabledSegment" label="Enable Segment"/>
                </div>
                <div class="ma-2 d-flex flex-column" >
                    <!-- ICON -->
                    <div class="ma-2">
                        <v-file-input v-model="headingIcon" label="Heading Icon"/>
                    </div>
                    <!-- HEADING -->
                    <div class="ma-2">
                        <v-text-field v-model="headingTitle" label="Heading Title"/>
                    </div>
                    <!-- Enabling Jump Links -->
                    <div class="ma-2">
                        <v-switch v-model="isEnableJumpLinkForSegment" label="Enable Jump Link for" />
                    </div>
                    <div class="ma-2" v-if="isEnableJumpLinkForSegment">
                        <v-text-field v-model="jumpLinkForSegmentTitle" label="Jump Link For Segment Title"/>
                        <v-select 
                        v-model="jumpLinkForSegment" 
                        :items="routeNameList" 
                        label="Jump Link For Segment"
                        />
                    </div>
                </div>
                <div class="ma-2 d-flex">
                    <div class=" ma-2 d-flex flex-column">
                        <div>
                            <v-select 
                            v-model="isCollectionOrCategory" 
                            label="Collection/Category"
                            :items="collectionCategoryItem"
                            item-value="id"
                            item-text="name"
                            return-object
                            @change="clearAllValuesInCards()"
                            />
                        </div>
                        <div v-if="isCollectionOrCategory != null">
                            <v-select 
                            v-model="selectedCollectionOrCagetoryList" 
                            :items="collectionOrCagetoryList" 
                            multiple 
                            chips
                            />
                            <img
                            src="https://d3vsnl8idgwrlw.cloudfront.net/add_book_appointment.svg"
                            @click="addCollectionOrCategory()"
                            aspect-ratio="1"
                            />
                            <div class="d-flex flex-column my-2" v-if="selectedCollectionOrCagetoryList != null">
                                <div class="d-flex my-2">
                                    <h1>Type: {{ isCollectionOrCategory.name }}</h1>
                                </div>
                                <div class="d-flex my-2"> 
                                    <v-card v-for="(collectionItem, index) in collectionList" :key="index">
                                        <v-card-title>
                                                <p>Position: {{collectionItem.positionValue}}</p>
                                        </v-card-title>
                                        <v-card-text>
                                            <p>Values: {{ collectionItem.collectionValue }}</p>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
            </v-card>
        </v-app>
    </div>
</template>

<script>
import NavBar from '../../navbar.vue';
import SideBar from '../../sideBar.vue';
import router from '@/router';
export default {
    name: '',
    components: {
        NavBar, SideBar
    },
    data() {
        return {
            segmentList: [1, 2],
            isEnabledSegment: false,
            headingIcon: null,
            headingTitle: '',
            isEnableJumpLinkForSegment: false,
            jumpLinkForSegmentTitle:'',
            jumpLinkForSegment:'',
            categoriesList: [],
            categoryItems: [11,22,33,44,55],
            isEnableCollection: false,
            isEnableCategory: false,
            collectionList: [],
            categoryList: [],
            orderValue: 1,
            categoryOrderValue: 1,
            collectionValue: '',
            categoryValue:'',
            jumpLinkForCollectionValue: '',
            jumpLinkForCategoryValue:'',
            routeNameList: [],
            collectionItems:[1,2,3,4,5],
            collectionCategoryItem: [{
                id: 1,
                name: 'Collection'
            },
            {
                id: 2,
                name: 'Category'
            }],
            isCollectionOrCategory: null,
            collectionOrCagetoryList: ['C1', 'C2', 'C3', 'C4', 'C5'],
            selectedCollectionOrCagetoryList: [],
        };
    },
    methods: {
        async clearAllValuesInCards() {
            this.collectionList = [];
        },
        async addCollectionOrCategory() {
            this.collectionList.push({
                type: this.isCollectionOrCategory.name,
                positionValue: this.orderValue, 
                collectionValue: this.selectedCollectionOrCagetoryList,
                jumpLinkForCollectionValue: this.jumpLinkForCollectionValue
            });
            this.orderValue = this.orderValue + 1;
            this.selectedCollectionOrCagetoryList = [];
            this.jumpLinkForCollectionValue = '';
        },
        async getRouteNames() {
            return router.options.routes
            .filter(route => route.visibility === true)
            .map(route => route.name);
        },
        changeCategory() {
            const selectedIndex = this.categoryItems.indexOf(this.categoriesList);
        }
    },
    async mounted() {
        this.routeNameList = await this.getRouteNames();
    }
}
</script>
<style scoped>
.image-btn {
  background-image: url('/path/to/image.jpg');
  background-size: cover;
  background-position: center;
}
</style>